(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("js-3d-model-viewer", [], factory);
	else if(typeof exports === 'object')
		exports["js-3d-model-viewer"] = factory();
	else
		root["js-3d-model-viewer"] = factory();
})(window, function() {
return 